/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

export const defaultFavicons = [
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon.ico',
        rel: 'shortcut icon',
        type: 'image/x-icon',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-16x16.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-32x32.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/mstile-70x70.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '70x70',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-96x96.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-128.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '128x128',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-196x196.png',
        rel: 'icon',
        type: 'image/png',
        sizes: '196x196',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-57x57.png',
        rel: 'apple-touch-icon',
        sizes: '57x57',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-60x60.png',
        rel: 'apple-touch-icon',
        sizes: '60x60',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-72x72.png',
        rel: 'apple-touch-icon',
        sizes: '72x72',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-76x76.png',
        rel: 'apple-touch-icon',
        sizes: '76x76',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-114x114.png',
        rel: 'apple-touch-icon',
        sizes: '114x114',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-120x120.png',
        rel: 'apple-touch-icon',
        sizes: '120x120',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-144x144.png',
        rel: 'apple-touch-icon',
        sizes: '144x144',
    },
    {
        href: 'https://www.startpage.com/sp/cdn/favicons/protection/apple-touch-icon-152x152.png',
        rel: 'apple-touch-icon',
        sizes: '152x152',
    },
]

// eslint-disable-next-line no-unused-vars
function SEO({title, description, lang, meta, ogImagePath, ogUrl, favicons = defaultFavicons}) {
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={title}
            link={[
                {
                    rel: `canonical`,
                    content: ogUrl,
                },
                ...favicons,
            ]}
            meta={[
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: description,
                },
                {
                    property: `og:url`,
                    content: ogUrl,
                },
                {
                    property: `og:image`,
                    content: ogImagePath,
                },
                {
                    property: `og:image:height`,
                    content: `196`,
                },
                {
                    property: `og:image:width`,
                    content: `196`,
                },
                {
                    property: `og:type`,
                    content: `article`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                // {
                //     name: `twitter:creator`,
                //     content: site.siteMetadata.author
                // },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: title,
                },
                {
                    name: `robots`,
                    content: `noindex,nofollow`,
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    title: ``,
    meta: [],
    description: ``,
    ogImagePath: 'https://www.startpage.com/sp/cdn/favicons/protection/favicon-196x196.png',
    ogUrl: `https://add.startpage.com`,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    ogImagePath: PropTypes.string,
    ogUrl: PropTypes.string,
}

export default SEO
