/** @jsx jsx */
/* eslint-disable no-unused-vars */
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import {jsx, css, Global} from '@emotion/react'
import {globalStyles} from '../constants'

const defaultGlobalStyles = globalStyles

const Layout = ({children, globalStyles = defaultGlobalStyles}) => {
    return (
        <>
            <Global styles={globalStyles} />
            <main
                css={css`
                    height: 100%;
                `}
            >
                {children}
            </main>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
