const Inter = `
    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 200;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-200-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 300;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-300-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 400;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-400-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 500;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-500-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 600;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-600-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 700;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-700-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }

    /* cyrillic-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-cyrillic-ext.woff2') format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }

    /* cyrillic */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-cyrillic.woff2') format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }

    /* greek-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-greek-ext.woff2') format('woff2');
        unicode-range: U+1F00-1FFF;
    }

    /* greek */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-greek.woff2') format('woff2');
        unicode-range: U+0370-03FF;
    }

    /* vietnamese */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-vietnamese.woff2') format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }

    /* latin-ext */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-latin-ext.woff2') format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }

    /* latin */
    @font-face {
        font-display: swap;
        font-family: 'Inter';
        font-weight: 800;
        src: url('https://www.startpage.com/sp/cdn/font/Inter/Inter-800-latin.woff2') format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
            U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
`

export default Inter
